<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in dtlFlds"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :shortcut="item.shortcut"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
        <!-- Registry Modal-->
        <b-modal
          :id="searchmodalName"
          size="xl"
          ok-only
          ok-variant="lisaweb"
          header-bg-variant="lisaweb"
          no-close-on-backdrop
          @hidden="onModalHidden"
        >
          <template #modal-header="{ cancel }">
            <div slot="modal-title" class="modal-title">
              <b-icon icon="search"></b-icon>
              <span>{{ detailTitle }} </span>
            </div>
            <button
              type="button"
              class="close"
              title="Chiudi"
              data-dismiss="modal"
              @click="cancel()"
            >
              &times;
            </button>
          </template>
          <div>
            <b-tabs content-class="pt-1" lazy v-model="tabIndex">
              <b-tab
                v-for="(tab, index) in tabs[entity]"
                :active="index === tabIndex"
                :key="index"
                :title="tab.text"
                @click="setTab(tab, index)"
                title-item-class="lisaweb"
              >
                <keep-alive>
                  <component
                    v-if="selected === tab.name"
                    :is="selected"
                    :resourceId="modalResourceId"
                  ></component>
                </keep-alive>
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
        <!-- Registry Modal-->
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";

export default {
  name: "General",
  mixins: [FormMixin, ShowMixin, ShortcutMixin],
  props: {
    resourceId: Number,
    dbRowData: {
      // tab Contraente deve avere i dati della polizza per Voltura
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    DetailsCard,
    registryGeneral: () =>
      import("@/components/wallet/registries/details/General"),
    registryCorrespondences: () =>
      import("@/components/wallet/registries/details/Correspondences"),
    registryInsurancePolicy: () =>
      import("@/components/wallet/registries/details/InsurancePolicy"),
    registryInsuranceAncillary: () =>
      import("@/components/wallet/registries/details/InsuranceAncillary"),
    registryTakings: () =>
      import("@/components/wallet/registries/details/Takings"),
    registryClaim: () => import("@/components/wallet/registries/details/Claim"),
    registryAppointments: () =>
      import("@/components/wallet/registries/details/Appointments"),
    registryTodo: () => import("@/components/wallet/registries/details/Todo"),
    registryNotes: () => import("@/components/wallet/registries/details/Notes"),
    registryGroup: () => import("@/components/wallet/registries/details/Group"),
    registryDocuments: () =>
      import("@/components/wallet/registries/details/Documents"),
    registryRelations: () =>
      import("@/components/wallet/registries/details/Relations"),
    registryPrints: () =>
      import("@/components/wallet/registries/details/Prints"),
  },
  data() {
    return {
      details: null,
      repositoryResourceId: {
        correspondence: {
          id: null,
        },
        registry: {
          id: null,
        },
      },
      status_registry_value: 0,
      repository: "insurance_policy",
      allRepositories: ["registry"],
      mandatoryFields: {
        registry: {
          status_registry: null,
          insurer_id: null,
        },
      },
      // registry modal
      searchmodalName: "registryModal",
      modalResourceId: null,
      detailTitle: null,
      entity: "registry",
      selected: null,
      tabIndex: 0,
      selTab: null,
      tabs: {
        registry: [
          { name: "registryGeneral", text: "Generale" },
          { name: "registryCorrespondences", text: "Indirizzi" },
          { name: "registryInsurancePolicy", text: "Polizze" },
          { name: "registryInsuranceAncillary", text: "Titoli" },
          { name: "registryTakings", text: "Incassi" },
          { name: "registryClaim", text: "Sinistri" },
          { name: "registryAppointments", text: "Appuntamenti" },
          { name: "registryNotes", text: "Note" },
          { name: "registryTodo", text: "Attività" },
          { name: "registryGroup", text: "Gruppi" },
          { name: "registryDocuments", text: "Doc" },
          { name: "registryRelations", text: "Relazioni" },
          { name: "registryPrints", text: "Stampe" },
        ],
      },
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        registry: {},
        correspondence: {},
      },
      detailFields1: {
        status_registry_1: {
          dati_anagrafici: {
            header: "Dati Anagrafici del contraente",
            edit: false,
            shortcut: {
              label: "Dettaglio Contraente",
              click: () => {
                this.openEntityModal(
                  this.repositoryResourceId.registry.id,
                  "registry",
                  this.getFoundLabel(this.details, "registry")
                );
              },
            },
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                option: "status_registry",
                value: "registry.status_registry.text",
              },
              {
                label: this.getDictionary("insurer_id", "registry"),
                option: "insurer_id",
                value: "insurer.title",
              },
              {
                label: this.getDictionary("attribute_CNAM_value", "registry"),
                value: "attributables.CNAM",
              },
              {
                label: this.getDictionary("attribute_LIAB_value", "registry"),
                value: "attributables.LIAB",
              },
            ],
          },
        },
        status_registry_0: {
          dati_anagrafici: {
            header: "Dati Anagrafici del contraente",
            edit: false,
            shortcut: {
              label: "Dettaglio Contraente",
              click: () => {
                this.openEntityModal(
                  this.repositoryResourceId.registry.id,
                  "registry",
                  this.getFoundLabel(this.details, "registry")
                );
              },
            },
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                option: "status_registry",
                value: "registry.status_registry.text",
              },
              {
                label: this.getDictionary("attribute_TITL_value", "registry"),
                value: "attributables.TITL",
              },
              {
                label: this.getDictionary("attribute_SURN_value", "registry"),
                value: "attributables.SURN",
              },
              {
                label: this.getDictionary("attribute_NAME_value", "registry"),
                value: "attributables.NAME",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "registry"),
                value: "attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_MARS_value", "registry"),
                value: "attributables.MARS",
              },
              {
                label: this.getDictionary("attribute_SEX_value", "registry"),
                value: "attributables.SEX",
              },
              {
                label: this.getDictionary("attribute_PROF_value", "registry"),
                value: "attributables.PROF",
              },
              {
                label: this.getDictionary("attribute_DOB_value", "registry"),
                value: "attributables.DOB",
              },
              {
                label: this.getDictionary("attribute_POB_value", "registry"),
                value: "attributables.POB",
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.POB",
                virtual: "PROVINCE",
              },
              {
                label: this.getDictionary("attribute_NOB_value", "registry"),
                value: "attributables.POB",
                virtual: "NATION",
              },
              {
                label: this.getDictionary("attribute_DOCN_value", "registry"),
                value: "attributables.DOCN",
              },
              {
                label: this.getDictionary("attribute_DOCT_value", "registry"),
                value: "attributables.DOCT",
              },
              {
                label: this.getDictionary("attribute_DOCX_value", "registry"),
                value: "attributables.DOCX",
              },
              {
                label: this.getDictionary("attribute_DOCS_value", "registry"),
                value: "attributables.DOCS",
              },
              {
                label: this.getDictionary("attribute_ISPL_value", "registry"),
                value: "attributables.ISPL",
              },
              {
                label: this.getDictionary("province_ispl"),
                value: "attributables.ISPL",
                virtual: "PROVINCE",
              },
              {
                label: this.getDictionary("country_ispl"),
                value: "attributables.ISPL",
                virtual: "NATION",
              },
            ],
          },
        },
        nascita: {
          header: "Nato a:",
          repository: "registry",
          edit: false,
          fields: [
            {
              label: this.getDictionary("attribute_POB_value", "registry"),
              value: "attributables.POB",
            },
            {
              label: this.getDictionary("province"),
              value: "attributables.POB",
              virtual: "PROVINCE",
            },
            {
              label: this.getDictionary("attribute_NOB_value", "registry"),
              value: "attributables.POB",
              virtual: "NATION",
            },
            {
              label: this.getDictionary("attribute_DOB_value", "registry"),
              value: "attributables.DOB",
            },
            {
              label: this.getDictionary("attribute_NOB2_value", "registry"),
              value: "attributables.NOB",
              virtual: "NATION2",
            },
            {
              label: this.getDictionary("attribute_POB2_value", "registry"),
              value: "attributables.POB2",
            },
          ],
        },
        residenza: {
          header: "Residenza / Sede Legale",
          repository: "correspondence",
          edit: false,
          fields: [
            {
              label: this.getDictionary(
                "attribute_NAME2_value",
                "correspondence"
              ),
              value: "attributables.NAME2",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_ADDR_value",
                "correspondence"
              ),
              value: "attributables.ADDR",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_CITY2_value",
                "correspondence"
              ),
              value: "attributables.CITY2",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary("province"),
              value: "attributables.CITY",
              relation: "correspondences",
              virtual: "PROVINCE",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_ZIPC_value",
                "correspondence"
              ),
              value: "attributables.ZIPC",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
            {
              label: this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              ),
              value: "attributables.CITY",
              virtual: "NATION",
              relation: "correspondences",
              status: 0,
            },
            {
              label: `${this.getDictionary(
                "attribute_NOR_value",
                "correspondence"
              )} estera`,
              value: "attributables.NOR",
              relation: "correspondences",
              status: 0, // Residenza / Sede Legale
            },
          ],
        },
      },
    };
  },
  methods: {
    openEntityModal(id, entity, title) {
      this.modalResourceId = id;
      this.entity = entity;
      this.selected = `${entity}General`;
      this.detailTitle = `Dettagli :: ${title}`;

      this.$bvModal.show(this.searchmodalName);
    },
    onModalHidden() {
      // override template's deinition
      this.entity = null;
      this.selected = null;
      this.tabIndex = 0;
    },
    setTab(tab, index) {
      console.log("select tab: ", tab.name);
      this.selected = tab.name;
      this.selTab = tab.name;
      this.tabIndex = index;
    },
    getEntityLabel(entity) {
      let ret = "";
      switch (entity) {
        case "registry":
          ret = "Anagrafiche";
          break;
        case "agency_contact":
          ret = "Contatti";
          break;
        case "claim":
          ret = "Sinistri";
          break;
        case "insurance_policy":
          ret = "Polizze";
          break;
      }
      return ret;
    },
    getFoundLabel(result, entity) {
      let ret;
      switch (entity) {
        case "registry":
          ret = `${result.title}`;
          break;
      }
      return ret;
    },
    fetch() {
      let filter;
      let queryString = `${filter ? filter : ""}`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data.registry;
          this.status_registry_value = data.status_registry.value;
          this.details = data;
          // move "custom" fields to the right place
          // NOTA qui non si può fare... la showForm è quella di insurance_ancillaries, non di registry...
          // quindi "custom" non c'è
          // if(Object.prototype.hasOwnProperty.call(this.detailFields, 'custom')) {
          //   this.detailFields["status_registry_0"]["custom"] = this.detailFields["custom"];
          //   this.detailFields["status_registry_1"]["custom"] = this.detailFields["custom"];
          // }

          this.repositoryResourceId.registry.id = data.id;
          // #233
          // se la polizza è a mano e lo stato della polizza è perfezionata (1), compare il tasto “voltura”
          // isImported
          let isImported = true;
          if (this.dbRowData.insurer) {
            isImported = this.dbRowData.insurer.importer_id ? true : false;
          }
          if (
            this.canVerb("insurance_policies", "edit") &&
            !isImported &&
            // this.details.status_policy.value == 1
            this.dbRowData.status_policy.value == 1
          ) {
            let s = {
              label: "Voltura",
              click: () => {
                this.shortcut(
                  "insurance_policies.transfer",
                  this.dbRowData.id,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  this.$options.name
                );
                // this.shortcut(
                //   "insurance_policies.transfer",
                //   this.dbRowData.id,
                //   null,
                //   null,
                //   null,
                //   {
                //     /* fromShortcut: true */
                //   },
                //   [
                //     {
                //       text: "Portafoglio",
                //       path: "",
                //       tab: "",
                //       level: 0,
                //       disabled: true,
                //     },
                //     {
                //       text: "Gestione Portafoglio",
                //       path: "module.PFOLIO",
                //       tab: "",
                //       level: 1,
                //     },
                //     {
                //       text: "Polizze",
                //       path: "module.PFOLIO",
                //       tab: "#Policies",
                //       level: 2,
                //     },
                //   ],
                //   // { item: this.details },
                //   null,
                //   this.$options.name
                // );
              },
            };
            this.$set(
              this.detailFields1.status_registry_0.dati_anagrafici,
              "shortcut",
              s
            );
            this.$set(
              this.detailFields1.status_registry_1.dati_anagrafici,
              "shortcut",
              s
            );
          }
          let shows = [];

          const sr = this.fetchEditForm(
            "registry",
            this.repositoryResourceId.registry.id
          );
          shows.push(sr);

          const sp = this.fetchEditForm("insurance_policy", this.dbRowData.id);
          shows.push(sp);
          if (this.details.correspondences.length) {
            this.repositoryResourceId["correspondence"].id =
              this.details.correspondences[0].id;
            const sc = this.fetchEditForm(
              "correspondence",
              this.repositoryResourceId["correspondence"].id
            );
            shows.push(sc);
          }

          Promise.all(shows).then(() => {
            this.initDetailCardFormData();
            this.isLoading = false;
          });
        })
        .catch((error) => {
          this.form = {};
          console.error(error);
        })
        .finally(() => {});
    },
  },
  computed: {
    dtlFlds() {
      // mergia i campi di status 0 o 1 con quelli in comune
      let { status_registry_0, status_registry_1, ...rest } =
        this.detailFields1;
      let c = this.status_registry_value
        ? status_registry_1
        : status_registry_0;
      let r = { ...c, ...rest };
      return r;
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetch();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
