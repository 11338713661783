var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _vm._l(_vm.dtlFlds, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        header: item.header,
                        edit: item.edit || false,
                        shortcut: item.shortcut,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.searchmodalName,
                        size: "xl",
                        "ok-only": "",
                        "ok-variant": "lisaweb",
                        "header-bg-variant": "lisaweb",
                        "no-close-on-backdrop": "",
                      },
                      on: { hidden: _vm.onModalHidden },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "modal-header",
                            fn: function ({ cancel }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal-title",
                                    attrs: { slot: "modal-title" },
                                    slot: "modal-title",
                                  },
                                  [
                                    _c("b-icon", { attrs: { icon: "search" } }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.detailTitle) + " "),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "close",
                                    attrs: {
                                      type: "button",
                                      title: "Chiudi",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return cancel()
                                      },
                                    },
                                  },
                                  [_vm._v("\n            ×\n          ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3369891808
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "b-tabs",
                            {
                              attrs: { "content-class": "pt-1", lazy: "" },
                              model: {
                                value: _vm.tabIndex,
                                callback: function ($$v) {
                                  _vm.tabIndex = $$v
                                },
                                expression: "tabIndex",
                              },
                            },
                            _vm._l(_vm.tabs[_vm.entity], function (tab, index) {
                              return _c(
                                "b-tab",
                                {
                                  key: index,
                                  attrs: {
                                    active: index === _vm.tabIndex,
                                    title: tab.text,
                                    "title-item-class": "lisaweb",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setTab(tab, index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "keep-alive",
                                    [
                                      _vm.selected === tab.name
                                        ? _c(_vm.selected, {
                                            tag: "component",
                                            attrs: {
                                              resourceId: _vm.modalResourceId,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }